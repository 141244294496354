<template>
  <div class="animated fadeIn">
    <CardTable :title="title"
               :columns="table.columns"
               :rows="table.rows"
               :isLoading="table.isLoading"
               :pageSize="serverParams.pageSize"
               :pageNumber="serverParams.pageNumber"
               :totalPage="table.totalPage"
               :totalRecords="table.totalRecords"
               @onRowSelected="onRowSelected"
               @onPageChange="onPageChange">
      <template #action>
        <div class="card-header-actions">
          <b-button :to="{ name: 'MeterManagementCreate' }"
                    size="sm"
                    variant="primary">
            Create New
          </b-button>
          <span style="margin-left: 10px">
            <b-button @click="exportAll"
                      size="sm"
                      variant="primary">
              Export
            </b-button>
          </span>
        </div>
      </template>
      <template #filter>
        <SectionFilter :serverParams="serverParams" @onChange="getAll" />
      </template>
      <template #cell(units)="data">
        {{ data.item.units.join(", ") }}
      </template>
      <template #cell(installedDate)="data">
        {{ dateFormat(data.item.installedDate) }}
      </template>
    </CardTable>
  </div>
</template>

<script>
  import { dateFormat } from "@/shared/utils";
  import { meterManagement as columns } from "@/shared/columns";
  import SectionFilter from "./SectionFilter.vue";

  export default {
    components: {
      SectionFilter,
    },
    data: () => ({
      title: "Meter Management",
      serverParams: {
        pageNumber: 1,
        pageSize: 500,
        keyword: null,
      },
      table: {
        isLoading: true,
        columns,
        rows: [],
        totalPage: 0,
        totalRecords: 0,
      },
    }),
    created() {
      const self = this;
      self.getAll();
    },
    methods: {
      dateFormat,
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
      onRowSelected(items) {
        const self = this;
        self.$router.push({
          name: "MeterManagementUpdate",
          params: {
            id: items[0].id,
          },
        });
      },
      onPageChange(params) {
        this.updateParams({ pageNumber: params });
        this.getAll();
      },
      getAll() {
        const self = this;

        self.table.isLoading = true;
        self.$store
          .dispatch("apis/get", {
            url: "/meter",
            params: self.serverParams,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.table.rows = response.data.data;
              self.table.totalPage = response.data.totalPage;
              self.table.totalRecords = response.data.totalData;
            }
            self.table.isLoading = false;
          });
      },
      exportAll() {
        console.log("export now");
        const self = this;
        self.$store
          .dispatch("apis/download", {
            url: "/meter/export",
            params: self.serverParams,
          })
          .then((response) => {
            console.log(response)
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              let fileURL = window.URL.createObjectURL(new Blob([response])),
                fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute("download", "meter-report.xls");
              document.body.appendChild(fileLink);

              fileLink.click();
            }
          });
      },
    },
  };
</script>
